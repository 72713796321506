<template>
  <div>
    <b-card
      v-if="dataList.length"
      no-body
    >
      <b-table-simple>
        <b-thead>
          <b-tr>
            <b-th>
              Başvuru Dosya Ekleri
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(item,index) in dataList"
            :key="item.id"
          >
            <b-td>
              <b-link
                :href="baseURL + '/media/crm/' + item.filename"
                target="_blank"
              >
                <FeatherIcon icon="PaperclipIcon" />
                Dosya Eki {{ index + 1 }}
              </b-link>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BLink,
} from 'bootstrap-vue'

export default {
  name: 'CrmFiles',
  components: {
    BCard,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BLink,
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['crmRequestFiles/dataList']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('crmRequestFiles/getDataList', {
        where: {
          'com_crm_files.id_com_crm': this.$route.params.id,
        },
      })
    },
  },
}
</script>
