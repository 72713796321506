<template>
  <b-card title="Müşteri Başvuru Geçmişi">
    <app-collapse
      accordion
      type="border"
    >
      <app-collapse-item
        v-for="history in customerCrmHistory"
        :key="history.id"
        :title="history.requestTitle"
      >
        <history-item :history-data="history" />
      </app-collapse-item>
    </app-collapse>
    <b-alert
      variant="info"
      show
      class="mt-2"
    >
      <div class="alert-body text-center">
        <FeatherIcon icon="InfoIcon" />
        Müşteri adına açılmış son 10 başvuru listelenmektedir.
      </div>
    </b-alert>
  </b-card>
</template>

<script>
import { BCard, BAlert } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
// eslint-disable-next-line import/extensions
import HistoryItem from '@/views/Crm/View/HistoryItem'

export default {
  name: 'History',
  components: {
    BCard,
    BAlert,
    AppCollapse,
    AppCollapseItem,
    HistoryItem,
  },
  computed: {
    customerCrmHistory() {
      return this.$store.getters['crmRequests/getCustomerCrmHistory']
    },
  },
}
</script>

<style scoped>

</style>
