<template>
  <div>
    <b-card title="Başvuru Bilgisi">
      <b-list-group>
        <list-item
          title="Başvuru No"
          :value="crmRequest.id"
        />
        <list-item
          title="Önem Derecesi"
          :value="crmRequest.crm_level"
        />
        <list-item
          title="Başvuru Kaynağı"
          :value="crmRequest.crm_applysource"
        />
        <list-item
          title="Başvuru Tipi"
          :value="crmRequest.feedback_type"
        />
        <list-item
          title="Başvuruyu Açan"
          :value="crmRequest.username"
        />
        <list-item
          title="Departman - 1"
          :value="crmRequest.department1"
        />
        <list-item
          title="Departman - 2"
          :value="crmRequest.department2"
        />
        <list-item
          title="Marka"
          :value="crmRequest.brand"
        />
        <list-item
          title="Lokasyon"
          :value="crmRequest.location"
        />
        <list-item
          title="Konu - 1"
          :value="crmRequest.subject1"
        />
        <list-item
          title="Konu - 2"
          :value="crmRequest.subject2"
        />
        <list-item
          title="Konu - 3"
          :value="crmRequest.subject3"
        />
        <list-item
          title="Atanan Yetkili - 1"
          :value="crmRequest.username1"
        />
        <list-item
          title="Atanan Yetkili - 2"
          :value="crmRequest.username2"
        />
        <list-item
          title="Atanan Yetkili - 3"
          :value="crmRequest.username3"
        />
        <list-item
          title="Onay Yetkilisi"
          :value="crmRequest.confirmation_user"
        />
      </b-list-group>
    </b-card>
  </div>
</template>

<script>
import { BCard, BListGroup } from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import ListItem from '@/views/Crm/View/ListItem'

export default {
  name: 'CrmRequest',
  components: {
    BCard,
    BListGroup,
    ListItem,
  },
  computed: {
    crmRequest() {
      return this.$store.getters['crmRequests/getRequest']
    },
  },
}
</script>

<style scoped>

</style>
