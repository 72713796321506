<template>
  <div>
    <public />
  </div>
</template>
<script>
// eslint-disable-next-line import/extensions
import Public from '@/views/Cars/view/Public'

export default {
  name: 'Customer',
  components: {
    Public,
  },
  computed: {
    car() {
      return this.$store.getters['cars/getCar']
    },
  },
}
</script>

<style scoped>

</style>
