<template>
  <b-row>
    <b-col cols="12">
      <b-tabs
        pills
        fill
      >
        <b-tab active>
          <template #title>
            <feather-icon icon="PhoneCallIcon" />
            <span>Başvuru</span>
          </template>
          <b-row>
            <b-col cols="4">
              <crm-request-info />
            </b-col>
            <b-col>
              <crm-request-detail />
              <answers />
            </b-col>
          </b-row>
        </b-tab>
        <b-tab lazy>
          <template #title>
            <feather-icon icon="UsersIcon" />
            <span>Müşteri Kartı</span>
          </template>
          <customer />
        </b-tab>
        <b-tab v-if="car">
          <template #title>
            <feather-icon icon="UsersIcon" />
            <span>Araç Kartı</span>
          </template>
          <car />
        </b-tab>
        <b-tab v-if="customerCrmHistory">
          <template #title>
            <feather-icon icon="ArchiveIcon" />
            <span>Başvuru Geçmiş</span>
          </template>
          <history />
        </b-tab>
      </b-tabs>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BTabs, BTab,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import Customer from '@/views/Crm/View/Customer'
// eslint-disable-next-line import/extensions
import Car from '@/views/Crm/View/Car'
// eslint-disable-next-line import/extensions
import CrmRequestInfo from '@/views/Crm/View/CrmRequestInfo'
// eslint-disable-next-line import/extensions
import CrmRequestDetail from '@/views/Crm/View/CrmRequestDetail'
// eslint-disable-next-line import/extensions
import History from '@/views/Crm/View/History'
// eslint-disable-next-line import/extensions
import Answers from '@/views/Crm/View/Answers'

export default {
  name: 'View',
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    Customer,
    Car,
    CrmRequestInfo,
    CrmRequestDetail,
    History,
    Answers,
  },
  computed: {
    crmRequest() {
      return this.$store.getters['crmRequests/getRequest']
    },
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    car() {
      return this.$store.getters['cars/getCar']
    },
    customerCrmHistory() {
      return this.$store.getters['crmRequests/getCustomerCrmHistory']
    },
  },
  watch: {
    crmRequest(val) {
      if (this.customer.id !== val.id_com_customer) {
        this.getCustomer(val.id_com_customer)
        this.getCustomerCrmHistory(val.id_com_customer)
      }
      // if (this.car.id) {
      if (this.car.id !== val.id_com_cars) {
        this.getCar(val.id_com_cars)
      }
      // }
    },
  },
  created() {
    this.getCrmRequest()
  },
  methods: {
    getCrmRequest() {
      this.$store.dispatch('crmRequests/requestView', this.$route.params.id)
    },
    getCustomer(id) {
      this.$store.dispatch('customers/customerView', id)
    },
    getCar(id) {
      this.$store.dispatch('cars/carView', id)
    },
    getCustomerCrmHistory(id) {
      this.$store.dispatch('crmRequests/customerCrmHistory', id)
    },
  },
}
</script>

<style scoped></style>
