<template>
  <div>
    <hr>
    <b-form-group
      label="Onay Yetkilisi Yorumu"
      label-for="comment"
    >
      <b-form-textarea
        id="comment"
        v-model="formData.comment"
        placeholder="Onay Yetkilisi Yorumu"
      />
    </b-form-group>
    <div class="text-center">
      <b-form-group
        label="Müşteri Memnuniyeti"
        label-for="rating"
      >
        <b-form-rating
          id="rating"
          v-model="formData.rating"
          inline
          size="lg"
          variant="warning"
        />
      </b-form-group>
    </div>
    <div class="text-center">
      <b-button
        variant="success"
        :disabled="submitStatus"
        @click="approved"
      >
        Onayla
      </b-button>
      <b-button
        variant="danger"
        :disabled="submitStatus"
        class="ml-1"
        @click="denied"
      >
        Reddet
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup, BFormTextarea, BButton, BFormRating,
} from 'bootstrap-vue'

export default {
  name: 'Buttons',
  components: {
    BFormGroup,
    BFormTextarea,
    BButton,
    BFormRating,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        id: null,
        comment: '',
        rating: 0,
        id_com_crm: this.$route.params.id,
      },
      submitStatus: false,
    }
  },
  computed: {
    saveStatus() {
      return this.$store.getters['crmSolutions/getCrmSolutionSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.getCrmRequest()
        this.getCrmSolutions()
        this.formData = {
          content: null,
          reach: null,
          id_com_crm: this.$route.params.id,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  methods: {
    approved() {
      this.formData.id = this.id
      this.formData.status = '1'
      this.submitStatus = true
      this.$store.dispatch('crmSolutions/crmSolutionStatusUpdate', this.formData)
    },
    denied() {
      this.formData.id = this.id
      this.formData.status = '0'
      this.submitStatus = true
      this.$store.dispatch('crmSolutions/crmSolutionStatusUpdate', this.formData)
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    getCrmSolutions() {
      this.$store.dispatch('crmSolutions/crmSolutionsList', {
        where: {
          'com_crm_solution.id_com_crm': this.$route.params.id,
        },
      })
    },
    getCrmRequest() {
      this.$store.dispatch('crmRequests/requestView', this.$route.params.id)
    },
  },
}
</script>

<style scoped></style>
