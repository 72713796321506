<template>
  <div>
    <b-form-group
      label="Müşteri İlişkileri Red Yorumu"
      label-for="comment"
    >
      <b-form-textarea
        id="comment"
        v-model="formData.comment"
        placeholder="Müşteri İlişkileri Red Yorumu"
      />
    </b-form-group>
    <div class="text-left">
      <b-button
        variant="danger"
        :disabled="submitStatus"
        @click="denied"
      >
        Reddet
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup, BFormTextarea, BButton,
} from 'bootstrap-vue'

export default {
  name: 'Buttons',
  components: {
    BFormGroup,
    BFormTextarea,
    BButton,
  },
  props: {
    id: {
      id: String,
    },
  },
  data() {
    return {
      submitStatus: false,
      formData: {
        id: null,
        comment: '',
        rating: 0,
        id_com_crm: this.$route.params.id,
      },
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    saveStatus() {
      return this.$store.getters['crmSolutions/getCrmSolutionSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.getCrmRequest()
        this.getCrmSolutions()
        this.formData = {
          content: null,
          reach: null,
          id_com_crm: this.$route.params.id,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  methods: {
    denied() {
      this.formData.id = this.id
      this.formData.status = '0'
      this.submitStatus = true
      this.$store.dispatch('crmSolutions/crmSolutionStatusUpdate', this.formData)
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    getCrmSolutions() {
      this.$store.dispatch('crmSolutions/crmSolutionsList', {
        where: {
          'com_crm_solution.id_com_crm': this.$route.params.id,
        },
      })
    },
    getCrmRequest() {
      this.$store.dispatch('crmRequests/requestView', this.$route.params.id)
    },
  },
}
</script>

<style scoped></style>
