<template>
  <validation-observer ref="simpleRules">
    <b-row>
      <b-col>
        <validation-provider
          #default="{ errors }"
          name="Çözüm yanıtı mı?"
          rules="required"
        >
          <b-form-group
            label="Çözüm yanıtı mı?"
            label-for="answer_type"
          >
            <v-select
              id="answer_type"
              v-model="answer_type"
              :options="answerTypes"
              :reduce="item => item.id"
              label="title"
              placeholder="Seçiniz"
            />
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <b-col>
        <validation-provider
          #default="{ errors }"
          name="Müşteriye ulaşıldı mı?"
          rules="required"
        >
          <b-form-group
            label="Müşteriye ulaşıldı mı?"
            label-for="reach"
          >
            <v-select
              id="reach"
              v-model="formData.reach"
              :options="reachOptions"
              :reduce="item => item.id"
              label="title"
              placeholder="Seçiniz"
            />
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <b-col cols="12">
        <validation-provider
          #default="{ errors }"
          name="Başvuru Yanıt"
          rules="required"
        >
          <b-form-group
            label="Başvuru Yanıt"
            label-for="content"
          >
            <b-form-textarea
              id="content"
              v-model="formData.content"
              placeholder="Başvuru Yanıt"
            />
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <b-col cols="12">
        <b-button
          variant="danger"
          :disabled="submitStatus"
          @click="submitForm"
        >
          Kaydet
        </b-button>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BFormGroup, BFormTextarea, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  name: 'AddAnswer',
  components: {
    BFormGroup,
    BFormTextarea,
    BRow,
    BCol,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      submitStatus: false,
      locale: 'tr',
      reach: null,
      answer_type: 0,
      content: null,
      formData: {
        content: null,
        reach: null,
        id_com_crm: null,
      },
      answerTypes: [
        {
          id: 1,
          title: 'Evet',
        },
        {
          id: 0,
          title: 'Hayır',
        },
      ],
      reachOptions: [
        {
          id: 1,
          title: 'Evet',
        },
        {
          id: 0,
          title: 'Hayır',
        },
      ],
    }
  },
  computed: {
    saveStatus() {
      return this.$store.getters['crmAnswers/getCrmAnswerSaveStatus']
    },
    saveStatus2() {
      return this.$store.getters['crmSolutions/getCrmSolutionSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.getCrmRequest()
        this.getCrmAnswers()
        this.getCrmSolutions()
        this.$refs.simpleRules.reset()
        this.formData = {
          content: null,
          reach: null,
          id_com_crm: this.$route.params.id,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
    saveStatus2(val) {
      if (val.status === true) {
        this.getCrmRequest()
        this.getCrmAnswers()
        this.getCrmSolutions()
        this.$refs.simpleRules.reset()
        this.formData = {
          content: null,
          reach: null,
          id_com_crm: this.$route.params.id,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    localize(this.locale)
    this.formData.id_com_crm = this.$route.params.id
  },
  methods: {
    getCrmRequest() {
      this.$store.dispatch('crmRequests/requestView', this.$route.params.id)
    },
    getCrmAnswers() {
      this.$store.dispatch('crmAnswers/crmAnswersList', {
        where: {
          'com_crm_answer.id_com_crm': this.$route.params.id,
        },
      })
    },
    getCrmSolutions() {
      this.$store.dispatch('crmSolutions/crmSolutionsList', {
        where: {
          'com_crm_solution.id_com_crm': this.$route.params.id,
        },
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          if (this.answer_type === 0) {
            this.$store.dispatch('crmAnswers/crmAnswerSave', this.formData)
          }
          if (this.answer_type === 1) {
            this.$store.dispatch('crmSolutions/crmSolutionSave', this.formData)
          }
        }
      })
    },
  },
}
</script>
