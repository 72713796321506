<template>
  <div>
    <b-card title="Başvuru Detayı">
      <b-list-group>
        <list-item
          title="Durum"
          :value="crmRequest.crm_status"
        />
        <list-item
          title="Başvuru Tarih / Saat"
          :value="crmRequest.created? moment(crmRequest.created).format('DD.MM.YYYY HH:mm') : null"
        />
        <b-list-group-item>
          <div class="font-weight-bold text-primary">
            Başvuru Metni
          </div>
          <div v-html="crmRequest.content" />
        </b-list-group-item>
      </b-list-group>
    </b-card>
    <crm-files />
  </div>
</template>

<script>
import { BCard, BListGroup, BListGroupItem } from 'bootstrap-vue'
import ListItem from '@/views/Crm/View/ListItem.vue'
import CrmFiles from '@/views/Crm/View/CrmFiles.vue'

export default {
  name: 'CrmRequest',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    ListItem,
    CrmFiles,
  },
  computed: {
    crmRequest() {
      return this.$store.getters['crmRequests/getRequest']
    },
  },
}
</script>
