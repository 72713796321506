<template>
  <div>
    <b-card title="Başvuru Yanıtları">
      <b-list-group>
        <answers-item
          v-for="answer in answers"
          :key="answer.id"
          :data="answer"
          data-type="answer"
        />
        <answers-item
          v-for="solution in solutions"
          :key="solution.id"
          :data="solution"
          data-type="solution"
        />
      </b-list-group>
      <div v-if="crmRequest.id_com_crm_status === '1' || crmRequest.id_com_crm_status === '2'">
        <hr>
        <add-answer />
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BListGroup,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import AnswersItem from '@/views/Crm/View/AnswersItem'
// eslint-disable-next-line import/extensions
import AddAnswer from '@/views/Crm/View/AddAnswer'

export default {
  name: 'Answers',
  components: {
    BCard,
    BListGroup,
    AnswersItem,
    AddAnswer,
  },
  computed: {
    answers() {
      return this.$store.getters['crmAnswers/getCrmAnswers']
    },
    solutions() {
      return this.$store.getters['crmSolutions/getCrmSolutions']
    },
    crmRequest() {
      return this.$store.getters['crmRequests/getRequest']
    },
  },
  created() {
    this.getCrmAnswers()
    this.getCrmSolutions()
  },
  methods: {
    getCrmAnswers() {
      this.$store.dispatch('crmAnswers/crmAnswersList', {
        where: {
          'com_crm_answer.id_com_crm': this.$route.params.id,
        },
      })
    },
    getCrmSolutions() {
      this.$store.dispatch('crmSolutions/crmSolutionsList', {
        where: {
          'com_crm_solution.id_com_crm': this.$route.params.id,
        },
      })
    },
  },
}
</script>
<style scoped></style>
