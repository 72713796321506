<template>
  <div>
    <b-table-simple>
      <b-tbody>
        <b-tr>
          <b-th class="width-200">
            Başvuru No
          </b-th>
          <b-td
            class="text-primary font-weight-bolder"
          >
            {{ HistoryData.id }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th class="width-200">
            Konu
          </b-th>
          <b-td>
            <b-badge
              v-if="HistoryData.subject1"
              variant="primary"
              class="mr-1"
            >
              {{ HistoryData.subject1 }}
            </b-badge>
            <b-badge
              v-if="HistoryData.subject2"
              variant="primary"
              class="mr-1"
            >
              {{ HistoryData.subject2 }}
            </b-badge>
            <b-badge
              v-if="HistoryData.subject3"
              variant="primary"
              class="mr-1"
            >
              {{ HistoryData.subject3 }}
            </b-badge>
          </b-td>
        </b-tr>
        <b-tr>
          <b-th class="width-200">
            Departman
          </b-th>
          <b-td>
            <b-badge
              v-if="HistoryData.department1"
              variant="warning"
              class="mr-1"
            >
              {{ HistoryData.department1 }}
            </b-badge>
            <b-badge
              v-if="HistoryData.department2"
              variant="warning"
              class="mr-1"
            >
              {{ HistoryData.department2 }}
            </b-badge>
          </b-td>
        </b-tr>
        <b-tr>
          <b-th class="width-200">
            Marka
          </b-th>
          <b-td>
            {{ HistoryData.brand }} - {{ HistoryData.location }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th class="width-200">
            Başvuru Tipi
          </b-th>
          <b-td>
            {{ HistoryData.feedback_type }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th class="width-200">
            Durum
          </b-th>
          <b-td class="text-danger font-weight-bolder">
            {{ HistoryData.crm_status }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th class="width-200">
            Atanan Yetkili
          </b-th>
          <b-td>
            <b-badge
              v-if="HistoryData.username1"
              variant="danger"
              class="mr-1"
            >
              {{ HistoryData.username1 }}
            </b-badge>
            <b-badge
              v-if="HistoryData.username2"
              variant="danger"
              class="mr-1"
            >
              {{ HistoryData.username2 }}
            </b-badge>
            <b-badge
              v-if="HistoryData.username3"
              variant="danger"
              class="mr-1"
            >
              {{ HistoryData.username3 }}
            </b-badge>
          </b-td>
        </b-tr>
        <b-tr>
          <b-th class="width-200">
            Başvuru Metni
          </b-th>
          <b-td>
            <div v-html="HistoryData.content" />
          </b-td>
        </b-tr>
        <b-tr>
          <b-td colspan="2">
            <b-button
              :href="'/crm/view/' + HistoryData.id"
              variant="outline-primary"
            >
              <FeatherIcon icon="EyeIcon" />
              Başvuruyu Görüntüle
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import {
  BTableSimple, BTbody, BTr, BTh, BTd, BBadge, BButton,
} from 'bootstrap-vue'

export default {
  name: 'HistoryItem',
  components: {
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BBadge,
    BButton,
  },
  props: {
    HistoryData: {
      type: Object,
    },
  },
}
</script>

<style scoped>

</style>
