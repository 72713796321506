<template>
  <b-card title="Araç Bilgisi">
    <b-list-group>
      <template v-if="car.swap_brand && car.swap_model">
        <list-item
          title="Marka"
          :value="car.swap_brand"
        />
        <list-item
          title="Model"
          :value="car.swap_model"
        />
      </template>
      <template v-else>
        <list-item
          title="Marka"
          :value="car.brand"
        />
        <list-item
          title="Model"
          :value="car.model"
        />
      </template>
      <list-item
        title="Donanım Paketi"
        :value="car.hardware"
      />
      <list-item
        title="Model Yılı"
        :value="car.myear"
      />
      <list-item
        title="Renk"
        :value="car.color"
      />
      <list-item
        title="Yakıt Tipi"
        :value="car.fuel"
      />
      <list-item
        title="Vites Tipi"
        :value="car.gear"
      />
      <list-item
        title="Plaka"
        :value="car.license_plate"
      />
    </b-list-group>
    <div class="text-center">
      <b-button
        variant="primary"
        class="mt-2"
        :to="'/cars/edit/' + car.id"
      >
        Bilgileri Güncelle
      </b-button>
    </div>
  </b-card>
</template>
<script>
import { BCard, BListGroup, BButton } from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import ListItem from '@/views/Cars/view/ListItem'

export default {
  name: 'Public',
  components: {
    BCard, BListGroup, BButton, ListItem,
  },
  computed: {
    car() {
      return this.$store.getters['cars/getCar']
    },
  },
}
</script>

<style scoped></style>
